<template>
  <div class="stripe-container">
    <van-nav-bar fixed  title="Pagamento" left-text="Indietro" left-arrow @click-left="onClickLeft" />
    <div class="stripe-content">
    <div class="stripe-head">
      <div class="shop_name">Paga <span v-if="shopInfo">{{shopInfo.shop_name}}</span> </div>
      <div class="total_ammount">{{data.total_order.toFixed(2)}} €</div>
    </div>
    <form id="payment-form">
      <label for="card-email">Email</label>
      <input v-model="billing.email" required type="email" id="email" />

      <label for="card-number">Informazioni sulla carta</label>
      <div id="card-number"></div>
      <div>
        <div id="card-expiry"></div>
        <div id="card-cvc"></div>
      </div>
      <div class="clearfix"></div>
      <div class="card-name">
        <label for="card-name">Titolare della carta</label>
        <input v-model="billing.name" required type="text" id="name" />
      </div>
      <button id="submit">
        <div class="spinner hidden" id="spinner"></div>
        <span id="button-text">Paga {{data.total_order.toFixed(2)}} €</span>
      </button>
      <p id="card-error" role="alert"></p>
      <p class="result-message hidden">
        Payment succeeded, see the result in your
        <a href="" target="_blank">Stripe dashboard.</a> Refresh the page to pay
        again.
      </p>
    </form>
    </div>
  </div>
</template>

<script>

export default {
  data(){
    return {
      data:null,
      shopInfo: null,
      stripePk: '',
      billing:{
        email:'',
        name:'',
      }
    }
  },
  created(){
    var data = JSON.parse(localStorage.getItem('data'))
    if(data == null){
          this.$router.push('/')
    }
    this.data = data
    this.axios.post('/webapi/appMobile/appGetShopInfo').then(res=>{

      this.shopInfo = res.data.data.shop_info
      this.stripePk = res.data.data.payment
      if(localStorage.getItem('user')){
        this.billing.email = localStorage.getItem('user')
      } 
    }).then(()=>{
      this.initialStripe(this.stripePk)
    })
  },
  mounted() {
 
  },

  methods: {
    initialStripe(stripePk) {
      var stripe = Stripe(stripePk);
      var _this = this;
      document.querySelector("button").disabled = true;

      var number = false
      var expiry = false
      var cvc = false

      var elements = stripe.elements();
      var style = {
        base: {
          color: "#32325d",
          fontFamily: "Arial, sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#32325d",
          },
        },
        invalid: {
          fontFamily: "Arial, sans-serif",
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };


      var cardNumber = elements.create("cardNumber", { style: style });
      cardNumber.mount("#card-number");

      var cardExpiry = elements.create("cardExpiry", { style: style });
      cardExpiry.mount("#card-expiry");

      var cardCvc = elements.create("cardCvc", { style: style });
      cardCvc.mount("#card-cvc");


      cardNumber.on("change", function (event) {
        // Disable the Pay button if there are no card details in the Element

        document.querySelector("#card-error").textContent = event.error
          ? event.error.message
          : "";
         if(!event.error){
           number = true
           document.querySelector("button").disabled = event.empty;
         }else{
           number = false
           document.querySelector("button").disabled = true;
         }
      });

      cardExpiry.on("change", function (event) {
        // Disable the Pay button if there are no card details in the Element
        document.querySelector("#card-error").textContent = event.error
          ? event.error.message
          : "";
          if(!event.error){
            expiry = true
          }else{
            expiry = false
         }
      });

      cardCvc.on("change", function (event) {
        // Disable the Pay button if there are no card details in the Element
        document.querySelector("button").disabled = event.empty;
        document.querySelector("#card-error").textContent = event.error
          ? event.error.message
          : "";
          if(!event.error){
            cvc = true
          }else{
            cvc = false
         }
      });

      var card = elements.getElement('cardNumber');
      var form = document.getElementById("payment-form");
      form.addEventListener("submit", function(event) {
        event.preventDefault();
        if(cvc == true && expiry == true && number == true){
          document.querySelector("button").disabled = true;

          var data = JSON.parse(localStorage.getItem('data'))
          
          _this.axios.post('/webapi/orders/appPay', data).then((result)=>{
            return result.data.clientSecret
          }).then((clientSecret)=>{
            _this.payWithCard(stripe, card, clientSecret)
          })
        }
        
      });

    },

    payWithCard(stripe, card, clientSecret){
      var _this = this
      this.loading(true);
      stripe.confirmCardPayment(clientSecret, {
      receipt_email: _this.billing.receipt_email,
      payment_method: {
        card: card,
         billing_details:{
           email: _this.billing.email,
           name: _this.billing.name
         }
      },   
    })
    .then(function(result) {
      if (result.error) {
        // Show error to your customer
        _this.showError(result.error.message);
      } else {
        _this.createOrder();
      }
    });
    },

    orderComplete(paymentIntentId) {
    this.loading(false);
    document
      .querySelector(".result-message a")
      .setAttribute(
        "href",
        "https://dashboard.stripe.com/test/payments/" + paymentIntentId
      );
    document.querySelector(".result-message").classList.remove("hidden");
    document.querySelector("button").disabled = true;
  },

  showError(errorMsgText) {
    this.loading(false);
    var errorMsg = document.querySelector("#card-error");
    errorMsg.textContent = errorMsgText;
    setTimeout(function() {
      errorMsg.textContent = "";
    }, 4000);
  },

  loading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      document.querySelector("button").disabled = true;
      document.querySelector("#spinner").classList.remove("hidden");
      document.querySelector("#button-text").classList.add("hidden");
    } else {
      document.querySelector("button").disabled = false;
      document.querySelector("#spinner").classList.add("hidden");
      document.querySelector("#button-text").classList.remove("hidden");
    }
  },

  createOrder() {
     var data = JSON.parse(localStorage.getItem('data'))
      this.axios
        .post("/webapi/Orders/createOrder", { data: data })
        .then((res) => {
          if (res.data.code != 200) {
            Dialog.alert({
              message: res.data.message,
              confirmButtonText: "OK",
            });
          } else {
            this.$store.commit("emptyCart");
            localStorage.setItem('data', null)
            this.$router.push({
              path: "/success/" + res.data.data.order_number,
            })
          }
        });
    },

    onClickLeft(){
      this.$router.go(-1)
    }

  },
};
</script>
<style>
.stripe-container {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  width: 100%;
  background: #fff;
  margin: 0 auto;
}
.stripe-content{
      height: 100vh;
      width: 100%;
      background: #fff;
      margin: 0 auto;
      min-width: 340px;
      max-width: 420px;
}
.stripe-head{
  width: 100%;
  padding: 46px 0;
  text-align: center;
}
.stripe-head .logo{

  margin-top: 60px;
}
.stripe-head .logo img{
  height: 40px;
  width: 100%;
  object-fit: contain;
}
.shop_name{
  font-size: 1.1em;
  margin-bottom: 5px;
}
.total_ammount{
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 5px;
}
.stripe-container form {
  width: 96%;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 10px;
}
.stripe-container input {
  border-radius: 6px;
  margin-bottom: 20px;
  margin-top: 5px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  font-size: 16px;
  width: 96%;
  background: white;
  padding: 0px 6px;
}
.result-message {
  line-height: 22px;
  font-size: 16px;
}
.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}
.hidden {
  display: none;
}
#card-error {
  color: rgb(105, 115, 134);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}
#card-number {
  border-radius: 4px 4px 0 0;
  padding: 12px 0px 12px 6px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  width: 96%;
  background: white;
}

#card-expiry, #card-cvc {
  padding: 12px 0px 12px 2px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  width: 48%;
  float: left;
  background: white;
}
#card-expiry {
  border-radius: 0 0 0 10px;
}
#card-cvc {
  border-radius: 0 0 10px 0;
}
.card-name{
  margin-top: 15px;
}

#payment-request-button {
  margin-bottom: 32px;
}
/* Buttons and links */
.stripe-container button {
  background: #5469d4;
  color: #ffffff;
  font-family: Arial, sans-serif;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 15px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 98%;
  margin-top: 15px;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}
/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

.clearfix{
  clear: both;
}
@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>